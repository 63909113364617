(function () {
  'use strict';

  angular
    .module('wbAccount')
    .config(config);

  function config($stateProvider, authenticatedOnly) {
    $stateProvider
      .state('account', {
        url: '/account',
        templateUrl: 'wb-account/views/account.tpl.html',
        controller: 'AccountCtrl',
        controllerAs: 'Account',
        resolve: {
          user: function (User, LoggedUser, _) {
            return _.defaultsDeep(new User(), LoggedUser.getUser());
          }
        },
        restrict: authenticatedOnly
      })
      .state('dashboard', {
        url: '/dashboard',
        templateUrl: 'wb-account/views/dashboard.tpl.html',
        controller: 'DashboardCtrl',
        controllerAs: 'dashboard',
        restrict: authenticatedOnly
      })
      .state('company', {
        url: '/company',
        templateUrl: 'wb-account/views/company.tpl.html',
        controller: 'CompanyCtrl',
        controllerAs: 'company',
        resolve: {
          company: function (LoggedUser) {
            return LoggedUser.getCompany();
          }
        },
        restrict: authenticatedOnly
      })
      .state('companySelector', {
        url: '/company-selector',
        templateUrl: 'wb-account/views/company-selector.tpl.html',
        controller: 'CompanySelectorCtrl',
        controllerAs: 'companySelector',
        resolve: {
          companies: function (LoggedUser, _, $state, $mdDialog, Auth, $document, $translate, SessionService) {
            var user = LoggedUser.getUser()
              , UserSubCompany = user.getCompany();

            return UserSubCompany
              .query()
              .$promise
              .then(function (data) {
                var dialog = $mdDialog
                  .alert({focusOnOpen: false})
                  .parent(angular.element($document.body))
                  .clickOutsideToClose(true)
                  .content($translate.instant('account.company.noCompany', {email: user.email}))
                  .ok($translate.instant('order.itemDialog.close'));

                if (data.length === 0) {
                  $mdDialog
                    .show(dialog)
                    .then(function () {
                      SessionService.clearSessionTimeout();
                      Auth.logout();
                      $state.go('login');
                    });
                }

                if (data.length === 1) {
                  return LoggedUser
                    .setCompany(_.first(data).id)
                    .then(function () {
                      $state.go('dashboard');
                    });
                }

                return data;
              });
          }
        },
        restrict: authenticatedOnly,
        data: {
          skipNavigation: true
        }
      })
      .state('orders', {
        url: '/orders',
        templateUrl: 'wb-account/views/orders.tpl.html',
        controller: 'OrdersCtrl',
        controllerAs: 'orders',
        restrict: authenticatedOnly
      });
  }
}());
